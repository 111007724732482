.Wrapper {
    height: 100vh;
    width: 100vw;
    text-align: center;
    justify-content: center;
    overflow-x: hidden;
}

.LargeCountdown {
    transform: scale(2.4);
    margin-top: 85pt;
}

video.stars-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    object-fit: cover;
    overflow: hidden;
}
