.button1 {
    background-color: transparent;
    border-radius: 15px;
    border: 1pt solid white;
    color: white;
    display: inline-block;
    font-size: 22px;
    margin: 10px;
    width: 190px;
    height: 60px;
    transition-duration: 0.4s;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.button1:hover {
    background-color: #ffffff33;
    cursor: pointer;
}

.icon {
    width: 30px;
    float: left;
    margin-right: 0;
    margin-left: 16px;
}

.center {
    margin-left: auto;
    margin-right: auto;
    border-collapse: separate; 
    border-spacing: 1em;
}

.some {
    text-align: left;
    float: left;
    margin-left: 16px;

}

@media screen and (max-width: 480px) {
    .button1 {
    font-size: 22px;
    text-align: center;
    align-items: center;
    margin: 5px;
    width: 250px;
    height: 60px;
    border-radius: 10px;
    padding: 0px;
    }

    .some {
        margin-left: 36px;
    
    }
}

  
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 1024px) {
    .button1 {
    font-size: 25px;
    width: 250px;
    height: 60px;
    border-radius: 10px;
    padding: 0px;
    }
}

.links {
    padding: 10px;
    height: auto; 
    justify-content: center;
    text-decoration: double;
    text-align: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    border-collapse: separate;
    border-spacing: 300px;
    font-weight: bold;
    color: white;
    line-height: 0.7;
    transition-duration: 0.4s;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

@media screen and (max-width: 480px) {
     .links h1 {
      font-size: 30px;
    }
  
    .links {
      width: 250px;
    }
  
    .links p {
      font-size: 14px;
    }
  
    .links h1 {
      font-size: 30px;
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1024px){
    .links {
      width: 400px;
    }

  }
  
  @media (min-width: 481px) and (max-width: 767px){
    .links {
      width: 400px;
    }
  
  }
  
  